
import Vue from "vue";
import API from "@/api/API";
import store from "@/store";

export default Vue.extend({
  name: "SelectSubscriptionPage",

  data: () => ({
    model: {},
    selectedPlanAlias: "" as string,
    infoDialog: false,
    showInfoButton: false,
    termsAndConditionsDialog: false
  }),

  async beforeRouteEnter(to, from, next) {
    try {
      const response = await API.billing().getPlans();

      next(vm => {
        vm.model = response;
      });
    } catch (e) {
      await store.dispatch("alert/showError", e.message);
    }
  },

  mounted() {
    setTimeout(() => {
      this.showInfoButton = true;
    }, 1000);
  },

  methods: {
    async selectPlan() {
      try {
        const response = await API.stripe().selectSubscriptionPlan(
          this.selectedPlanAlias
        );

        this.$stripe.redirectToCheckout({
          sessionId: response.checkoutSession.id
        });
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    openTermsAndConditionsDialog(planAlias: string) {
      this.selectedPlanAlias = planAlias.split('_')[0];
      this.termsAndConditionsDialog = true;
    },
    confirmTermsAndConditions() {
      this.termsAndConditionsDialog = false;
      this.redirectToCheckout();
    },
    redirectToCheckout(){
      this.$router.push('/billing/plan/' + this.selectedPlanAlias);
    }
  }
});
