
import Vue from "vue";
import API from "@/api/API";
import store from "@/store";

interface Plan{
  [key: string] : {}
}

export default Vue.extend({
  name: "PaymentPage",

  data: () => ({
    model: {
      plans: {} as Plan
    },
    selectedPlan: {},
    planExists: false as  boolean,
    logoImage: require("@/assets/images/logos/logo-icon.png"),
    user: store.getters["user/info"],
    cardNumber: "" as string,
    creditCardExpiry: "" as string,
    cardCVC: "",
    loading: false as boolean,
    cardName: "",
    cardCountry: "",
    cvcIcon: require("@/assets/images/card.png"),
    banks: require("@/assets/images/banks.png"),
    errors: null as any
  }),

  async beforeRouteEnter(to, from, next) {
    try {
      const response = await API.billing().getPlans();

      next(vm => {
        vm.model = response;
      });
    } catch (e) {
      await store.dispatch("alert/showError", e.message);
    }
  },

  mounted() {
    let queryPlan;
    if(this.$route.params.id == 'report')
      queryPlan = this.$route.params.id + "_care_new";
    else
      queryPlan = this.$route.params.id + "_new";

    if(this.model.plans.hasOwnProperty(queryPlan)){
      this.planExists = true;
      this.selectedPlan = this.model.plans[queryPlan]
    }
    else
      this.$router.push('/billing/select-plan')
  },

  methods: {
    formatcreditCardExpiry() {
      console.log(this.creditCardExpiry);
      // Remove any non-digit characters
      let formattedDate = this.creditCardExpiry.replace(/\D/g, '');
      // Add a slash (/) between month and year
      if (formattedDate.length > 2) {
        formattedDate = formattedDate.slice(0, 2) + '/' + formattedDate.slice(2);
      }
      if(formattedDate.length > 5){
        formattedDate = formattedDate.slice(0, 5)
      }
      this.creditCardExpiry = formattedDate;
    },

    validateCVC(){
      let formattedCVC = this.cardCVC.replace(/\D/g, '');
      if(formattedCVC.length > 3)
        formattedCVC = formattedCVC.slice(0,3);
      this.cardCVC = formattedCVC
    },

    validateCardNumber(){
      let cardNumber = this.cardNumber.replace(/\D/g, '');
      if(cardNumber.length > 16)
        cardNumber = cardNumber.slice(0,16);
      this.cardNumber = cardNumber;
    },

    async submitForm(event: any){
      event.preventDefault()

      this.errors = [];
      // validate the form
      if(!this.user.email)
        this.errors.push("Email is required!");

      if(!this.user.name)
        this.errors.push("Name is required!");

      if(!this.cardNumber)
        this.errors.push("Card number is required!");

      if(!this.creditCardExpiry)
        this.errors.push("Card expire date is required!");

      if(!this.cardCVC)
        this.errors.push("Card CVC is required!");

      if(this.errors.length)
        return false;

      // Start the form processing
      this.loading = !this.loading

      // create the payload to be sent
      let payload = {
        user: this.user,
        cardDetails: {
          cardNumber: this.cardNumber,
          cardCVC: this.cardCVC,
          creditCardExpiry: this.creditCardExpiry,
        },
        planDetails: this.selectedPlan
      }
      const response = await this.$API.authorize().createSubscription(payload)

      if(response.success){
        await this.$router.push('/billing/successful-payment')
      }else{
        await this.$store.dispatch("alert/showError", response.message);
      }

      this.loading = false;
      console.log(response)
    },
    
    formatTheAmount(price:string){
      return parseInt(price).toFixed(2)
    }
  }
});
