
import Vue from "vue";
import API from "@/api/API";
import store from "@/store";
import Rewardful from "vue-rewardful";
declare function rewardful(arg: any, arg1: any): void;

Vue.use(Rewardful, {
  key: "e9e80c"
});

export default Vue.extend({
  name: "PaymentSuccess",

  data: () => ({
    subscription: {} as any
  }),

  mounted() {
    if (this.subscription.amount_paid > 100) {
      rewardful("convert", { email: this.subscription.email });
    }
  },

  async beforeRouteEnter(to, from, next) {
    try {
      const subscription = await API.billing().getRecentSubscription();

      if (!subscription.period) {
        next("/errors/404");
      }

      next(vm => {
        vm.subscription = subscription;
        store.dispatch("preloader/hideGlobal");
      });
    } catch (e) {
      await store.dispatch("alert/showError", e.message);
    }
  },

  async beforeMount() {
    await this.generateTermsAndConditionsDocument();
  },

  computed: {
    formattedPeriod() {
      const start = this.formatPeriod(this.subscription.period?.start);
      const end = this.formatPeriod(this.subscription.period?.end);

      return `${start} - ${end}`;
    }
  },

  methods: {
    async generateTermsAndConditionsDocument() {
      const documentUrl = (await this.$API
        .documents()
        .generateTermsAndConditionsDocument()) as any;
      console.log(documentUrl);
      window.open(documentUrl.url, "_blank");
    },
    getFormattedDate(date: number): string {
      return this.$moment(date * 1000).format("MM/DD/YYYY");
    },
    formatPeriod(date: any) {
      return this.$moment(date * 1000).format("MM/DD/YYYY, hh:mm");
    }
  }
});
